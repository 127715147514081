import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import {Link, graphql } from "gatsby"
import { Styled, Grid, Box, Message, Badge } from 'theme-ui'
import '../styles/essays.css'

function Blogs({ data }) {
  return (
    <Layout>
    <SEO title="essays" />
    <h1>NightSky Essays</h1>
    <ul style={{display:"flex", flexWrap :"wrap", marginLeft:"0"}}>
    {data.allWordpressCategory.edges.map(({ node }) => (
        <Styled.a as={Link} to={'/category/' + node.name}>
          <li style={{paddingRight: "1.45rem", listStyle:"none"}}>{node.name.charAt(0).toUpperCase() + node.name.slice(1)}
          </li>
          </Styled.a>
    ))}
          </ul>
    <Message style={{backgroundColor:"#838383"}}>
      Choose a topic: click on the link or photo.
      Follow the instructions: The essay will be mailed to you as a pdf.
      If you decide to forward a copy to your friends, it is 
      best to forward it to yourself and put your friends' address in BCC.
      This helps protect their privacy. Pointer: to avoid being junked, copy and paste 
      my email address into your contacts.
    </Message>
      <br></br>
    {data.allWordpressPost.edges.map(({ node }) => (
      <Grid key={'/essays/' + node.slug} gap={2}
      columns={[2, '1fr 2fr']} className="essay-display">
        <Box>
        <Styled.a as={Link}  to={'/essays/' + node.slug}>
        <Img key={node.featured_media.localFile.childImageSharp.resolutions.src} fluid={node.featured_media.localFile.childImageSharp.fluid} 
        style ={{ padding:2}}/>
        </Styled.a>
        </Box>
        <Box>
        <Styled.a as={Link}  to={'/essays/' + node.slug}>
        <h3 dangerouslySetInnerHTML={{__html: node.title}} />
        </Styled.a>
        {/* <Badge>{node.categories[0].name.charAt(0).toUpperCase() + node.categories[0].name.slice(1)}</Badge> */}
        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </Box>
        <br></br>
      </Grid>
    ))}
  </Layout>
  )
}

export default Blogs

export const query = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
        edges {
          node {
            title
            excerpt
            date(formatString: "MMMM DD, YYYY")
            slug
            categories {
              id
              name
            }
            featured_media {
              localFile {
                  childImageSharp {
                    fluid(maxWidth:300, maxHeight:300){
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                    resolutions(width:350, height: 500) {
                      ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                    }
                  }
              }
            }
          }
        }
      }
      allWordpressCategory {
        edges {
          node {
            name
            slug
            wordpress_id
          }
        }
      }
  }
`